/* Colors */

[data-theme = 'cold'] {
  --body-background: linear-gradient(to left, #243B55, #141E30);
  --input-focus: #284b73;
  --button-background: linear-gradient(to left, #385575, #2b3d5f);
  --footer-color: #0092da;
  --footer-border-color: #3d5e8a;
}

[data-theme = 'warm'] {
  --body-background: linear-gradient(to left, #905a5e, #44183c);
  --input-focus: #511e42;
  --button-background: linear-gradient(to left, #973165, #7a2d63);
  --footer-color: #ff8b8b;
  --footer-border-color: #8e555e;
}

/* Styles */

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  background: var(--body-background);
}

#root {
  padding: 0 15px;
}

h2 {
  text-align: center;
}
