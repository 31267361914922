.App {
  margin: 35px auto 5px;
  height: 640px;
  max-height: 640px;
  max-width: 360px;
  background-size: cover;
  background-position: bottom;
  transition: 0.4s ease;
  border-radius: 5px;
  overflow: hidden;
}

[data-theme = 'cold'] .App {
  background-image: url("assets/img/cold-bg.jpg");
}

[data-theme = 'warm'] .App {
  background-image: url('assets/img/warm-bg.jpg');
}

.App h2 {
  margin-top: 0;
}

.App main {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.75));
  padding: 20px 15px;
  height: 640px;
  max-height: 640px;
  max-width: 360px;
}
