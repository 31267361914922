.footer {
  max-width: 355px;
  padding: 15px;
  margin: auto;

}

.footer .footer-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.footer .footer-container .copyright {
  margin-bottom: 15px;
}

.footer .footer-container .copyright .author {
  color: var(--footer-color);
  font-weight: 600;
}

.footer .footer-container .links a {
  font-size: 18px;
  transition: 0.3s;
  color: #ffffff;
}

.footer .footer-container .links a:hover {
  color: var(--footer-color);
}

.footer .footer-container .links i {
  padding: 0 6px;
}

@media (max-width: 576px) {
  .footer .footer-container {
    flex-direction: column;
  }

  .footer .footer-container .copyright {
    margin-bottom: 15px;
  }
}
